'use client';

import { useEffect, useRef } from "react";


export const CalculateWidthBody = () => {
    const refMainContainer = useRef<HTMLDivElement>(null);


    const calculateWidthBody = () => {
        const width = refMainContainer.current?.getBoundingClientRect().width;
        document.documentElement.style.setProperty('--main-container-width', `${width}px`);
    };

    useEffect(() => {
        calculateWidthBody();
    }, []);

    useEffect(() => {
        window.addEventListener('resize', calculateWidthBody);

        return () => {
            window.removeEventListener('resize', calculateWidthBody);
        }
    }, []);
    return (
        <div ref={refMainContainer} style={{ width: '100%' }}></div>
    );
};

export default CalculateWidthBody;