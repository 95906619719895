'use client'; // Esto indica que el componente es del lado del cliente

import { useState, useEffect } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import './toogleWhatsapp.scss';

const ToggleWhatsApp = () => {
    const [showWhatsApp, setShowWhatsApp] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const documentHeight = document.documentElement.scrollHeight;
            const windowHeight = window.innerHeight;

            // Mostrar el ícono cuando el usuario se acerque al final de la página
            if (scrollPosition + windowHeight >= documentHeight - 800) {
                setShowWhatsApp(true);
            } else {
                setShowWhatsApp(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <>
            <div className={`whatsapp-icon-container ${!showWhatsApp ? 'hidden' : ''}`}>
                {showWhatsApp && (
                    <div className="whatsapp-content">
                        <span className="whatsapp-text">¡Hablemos!</span>
                        <Link
                            href="https://wa.me/34655651561"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Contáctanos por WhatsApp para más información sobre nuestros servicios"
                            title="Contacto por WhatsApp"
                            className="whatsapp-icon animate-fade-in" // Aplicamos las clases definidas en el SCSS
                        >
                            <Image
                                src="/images/icons/whatsapp.png"
                                alt="Icono de WhatsApp para contactar"
                                width="40"
                                height="40"
                                loading="lazy"
                            />
                        </Link>
                    </div>
                )}
            </div>
        </>
    );
};

export default ToggleWhatsApp;
