import { client } from "@/sanity/lib/client";

export type IUser = {
    name: string,
    message: string,
    email: string,
    phone: string,
}


// generate random UUID
const uuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = Math.random() * 16 | 0,
            v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
const createUser = async (user: IUser) => {
    const { name, email, phone, message } = user;
    const data = await client.createIfNotExists({
        name,
        email,
        message,
        phone,
        date: new Date().toLocaleString(),
        _type: 'user',
        _id: uuid(),
    });
    return data as IUser;
}

export {
    createUser,
}