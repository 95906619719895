import { createClient } from 'next-sanity'

import { dataset, projectId, useCdn, token } from '../env'

export const client = createClient({
  projectId,
  dataset,
  useCdn,
  token,
  withCredentials: true, 
})
